.section {
  @apply py-10 md:py-11 lg:py-12;
}

.hover_underline {
  text-decoration: unset;
  position: relative;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hover_underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust based on your text size */
  width: 100%;
  height: 2px; /* Thickness of the underline */
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  @apply bg-primary;
}

.hover_underline:hover::after {
  transform: scaleX(1);
}

.active_menu.hover_underline::after {
  transform: scaleX(1);
}

.footer-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/assets/bg_images/nest_bg.png');
  background-size: 800px;
  background-position: right bottom;
  background-repeat: no-repeat;
  opacity: 0.5; /* Adjust this value to change the opacity */
  z-index: -1;
}

.social_icon,
.social_icon > * {
  @apply transition-colors;
}

.social_icon:hover {
  @apply fill-primary text-primary *:fill-primary;
}

.mobile_nav_backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 15;
  backdrop-filter: blur(4px);
}

.hero-banner {
  position: relative;
}

.hero-banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/assets/bg_images/nest_bg.png');
  background-size: 1440px;
  background-position: right bottom;
  background-repeat: no-repeat;
  opacity: 1; /* Adjust this value to change the opacity */
  z-index: -1;
}

.section_title {
  @apply text-lg md:text-2xl font-semibold;
}

.section_sub_title {
  @apply text-sm text-darkestGray;
}

.service-card {
  @apply shadow-card rounded-lg px-2 py-8 pb-10 md:p-8 md:pt-16 md:pb-14 transition-colors;
}

.service-card:hover {
  @apply bg-primary;
}

.service-card-image {
  position: relative;
  background-image: url('/public/assets/services-images/social-media.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1; /* Adjust this value to change the opacity */
  z-index: 1;
  @apply w-12 h-12 md:w-20 md:h-20;
}

.slogan-container {
  position: relative;
}

.slogan-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/assets/bg_images/nest_bg_leaf_from_left.png');
  background-size: 200px;
  background-position: left bottom;
  background-repeat: no-repeat;
  opacity: 0.5; /* Adjust this value to change the opacity */
}

.slogan.btn_link::after {
  transform: scaleX(0.5);
}

.btn_link {
  @apply relative;
}

.btn_link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 1px;
  transform: scaleX(0);
  transform-origin: left;
  @apply transition-all;
}

.btn_link:hover:after {
  transform: scaleX(1);
}

/** ------------------------------ ABOUT ------------------------------ */

.our-team-img-wrapper {
  position: relative;
  @apply transition-all;
}

.our-team-img-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid;
  z-index: -1;
  opacity: 1;
  @apply rounded-lg border-primary;
}

.our-team-img-wrapper:hover .our-team-img {
  transform: translate(0px, 0px);
  @apply shadow-card;
}

.our-team-img-wrapper:hover::before {
  opacity: 0;
}

.our-team-img {
  transform: translate(-8px, -8px);
  @apply rounded-lg transition-all;
}

/** ------------------------------ SHOWCASE ------------------------------ */

.showcase .image-container {
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  @apply rounded-lg  transition-all;
}

.showcase .image-container::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid;
  z-index: -1;
  opacity: 0;

  @apply border-primary rounded-lg transition-opacity;
}

.showcase .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  @apply shadow-card rounded-lg transition-all;
}

.showcase .image-container:hover img {
  transform: translate(-8px, -8px);
  @apply -translate-x-1 -translate-y-1 md:-translate-x-2 md:-translate-y-2;
}

.showcase .image-container:hover::after {
  opacity: 1;
}

.gallery_btn {
  @apply absolute transform -translate-y-1/2 bg-lightGray bg-opacity-80 p-2 rounded-lg;
}

.showcase.event__planning .gallery_image {
  background-color: unset !important;
}

/** ------------------------------ MEDIA QUERY ------------------------------ */

@media screen and (max-width: 480px) {
  body {
    font-size: 14px;
  }

  .hero-banner::after {
    background-size: 500px;
  }

  .footer-container::before {
    background-image: unset;
    display: none;
  }

  .copy-right-text {
    font-size: 13px;
  }

  .slogan-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/public/assets/bg_images/nest_bg_leaf_from_left.png');
    background-size: 100px;
    background-position: left bottom;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust this value to change the opacity */
  }
}
