/* -------------------- Poppins -------------------- */

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

/* -------------------- Bellerose -------------------- */

@font-face {
  font-family: 'Bellerose';
  src: url('../assets/fonts/Bellerose/Bellerose.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

/* -------------------- NotoSan Myanmar -------------------- */

@font-face {
  font-family: 'NotoSan-MM';
  src: url('../assets/fonts/Noto_Sans_Myanmar/NotoSansMyanmar-Thin.ttf') format('truetype');
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSan-MM';
  src: url('../assets/fonts/Noto_Sans_Myanmar/NotoSansMyanmar-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSan-MM';
  src: url('../assets/fonts/Noto_Sans_Myanmar/NotoSansMyanmar-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSan-MM';
  src: url('../assets/fonts/Noto_Sans_Myanmar/NotoSansMyanmar-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSan-MM';
  src: url('../assets/fonts/Noto_Sans_Myanmar/NotoSansMyanmar-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSan-MM';
  src: url('../assets/fonts/Noto_Sans_Myanmar/NotoSansMyanmar-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSan-MM';
  src: url('../assets/fonts/Noto_Sans_Myanmar/NotoSansMyanmar-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSan-MM';
  src: url('../assets/fonts/Noto_Sans_Myanmar/NotoSansMyanmar-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSan-MM';
  src: url('../assets/fonts/Noto_Sans_Myanmar/NotoSansMyanmar-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
